/* body { max-width: 100vw; overflow-x: hidden; } */

#about-me{
    background-size: cover;
    font-family: 'Tajawal', sans-serif;
}

.container-intro{
    height: 80%;
    background:rgb(155, 219, 200);
}

.image-intro{
    margin-top: 5%;
    /* margin-left: 4%; */
    margin-bottom: 5%;
    border-radius: 50%;
    border:5px solid #00485e;
    width: 75%;
    height: auto;
}

.message-text{
    margin-top: 30px;
    text-align: center;
    font-size: 45px;
    color:rgb(111, 202, 175) !important;
}

.message-subtext{
    text-align: center;
    font-size: 20px;
    color:#506163 !important;
}

.large-intro-text{
    margin-top: 3%;
    font-size: 50px;
    color: #005475;
}

.medium-intro-text{
    margin-top: 1%;
    font-size: 25px;
    max-width: 90%;
    color: #506163;
}

.small-intro-text{
    margin-top: 1%;
    font-size: 20px;
    max-width: 90%;
    color: #506163;
}